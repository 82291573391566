.nav {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  padding: 5px 25px;
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.1);
  z-index: 10;
  position: fixed;
  top: 0; /* Make sure it's fixed to the top */
  transition: transform 0.3s ease; /* Smooth transition */
}

/* Add this to your existing Navbar.css */

.nav.hidden {
  transform: translateY(-100%); /* Move navbar out of view */
}

.nav.visible {
  transform: translateY(0); /* Bring navbar into view */
}

/* Other styles remain unchanged */

.logo {
  font-size: larger;
  font-weight: 600;
}

.navbar-ul {
  display: flex;
  width: auto;
  list-style-type: none;

  gap: 45px;

  font-size: 16px;
  font-family: "Inter", sans-serif;
  padding: 0;
  margin: 0;
}
.navbar-li {
  cursor: pointer;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none !important;
  cursor: pointer;
  font-weight: 500;
}

.navbar-ul li a.active {
  color: #d80e0e; /* Change to the desired color for the active link */
  border-bottom: 2px solid #d80e0e; /* Optional: add a border to the active link */
  font-weight: bold; /* Optional: make the active link bold */
}

a:hover {
  transition: 0.3s;
  border-bottom: 2px solid #d80e0e;
  color: #d80e0e !important;
}
/* /* a.active {
  border-bottom: 2px solid #d80e0e;
  color: #d80e0e !important;
} */

.nav_contact_button {
  padding: 14px 25px;
  background-color: #d80e0e;
  color: white;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
  border: none;
  -webkit-transition: ease-out 0.4s;
  -moz-transition: ease-out 0.4s;
  transition: ease-out 0.4s;
}

.nav_contact_button:hover {
  box-shadow: inset 400px 0 0 0 #e92d2d;
}

.menu_icon {
  display: none;
  z-index: 10;
}
.contact-btn-link {
  border-bottom: none !important;
}
@media screen and (max-width: 1125px) {
  .nav {
    display: none !important;
  }
  .nav.open {
    width: 90%;
    display: flex !important;
    flex-direction: column;
    position: fixed;
    top: 50px;
    align-items: center;
    align-self: center;
    align-content: center;
    left: 50%;
    transform: translateX(-50%);
    padding: 20px 0 20px 0;
    z-index: 10;
  }

  .menu_icon {
    display: block;
    position: absolute;
    right: 15px;
    top: 10px;
  }
  .navbar-ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 30px 0 30px 0;
    gap: 30px;
  }
}

@media screen and (max-width: 768px) {
  .nav {
    display: none !important;
  }
  .nav.open {
    width: 90%;
    display: flex !important;
    flex-direction: column;
    position: fixed;
    top: 50px;
    align-items: center;
    align-self: center;
    align-content: center;
    left: 50%;
    transform: translateX(-50%);
    padding: 20px 0 20px 0;
    z-index: 10;
  }

  .menu_icon {
    display: block;
    position: absolute;
    right: 15px;
  }
  .navbar-ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 30px 0 30px 0;
    gap: 30px;
  }
}

/* @media (max-width: 1042px) {
  .nav.open {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 50px;
    padding: 30px 0;
    justify-content: center;
    align-items: center;
  }

  .menu_icon {
    display: block;
    position: absolute;
    right: 10px;
  }
  .navbar-ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 30px 0 30px 0;
  }

  .nav {
    display: none;
    height: auto;
  }
} */
