.footer {
  width: 100%;
  height: auto;
  background: #000000;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 65px 0;
  margin-top: 150px;
  gap: 60px;
}

.footer-body {
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 0 20px; */
}

.footer-items {
  width: auto;
  height: auto;
  display: flex;
  gap: 130px;
  align-items: center;
  justify-content: space-evenly;
  padding: 0 0 0 80px;
}

.footer-div1 {
  display: flex;
  flex-direction: column;
  width: auto;
  height: auto;
  font-size: 19.2px;
  font-weight: 400;

  text-align: left;
}

.footer-span {
  display: flex;
  align-items: center;
}

.footer-li {
  width: auto;

  list-style-type: none;
  text-decoration: none;
  cursor: pointer;
  margin-bottom: 28px;
}

.copyright {
  width: auto;
  font-size: 19.2px;
  font-weight: 400;
  line-height: 45.37px;
}

.footer-li a {
  color: #ffffff !important;
  border-bottom: none;
}
@media screen and (max-width: 1232px) {
  .footer-body {
    width: 95%;
    flex-direction: column;
    gap: 30px;
    padding: 0;
  }
  .footer-items {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0;
    gap: 0;
  }

  .footer-div1 {
    margin: 20px;
  }

  .footer-li {
    text-align: center;
  }

  .footer-span {
    justify-content: center;
    /* width: 150px; */
    margin: auto;
    gap: 20px;
  }
}

@media screen and (max-width: 768px) {
  .footer {
    gap: 40px;
  }
  .footer-body {
    width: 95%;
    gap: 30px;
    flex-direction: column;
    padding: 0;
  }
  .footer-items {
    grid-template-columns: 1fr;
    gap: 0;
  }

  .footer-span {
    gap: 0;
  }

  .footer-div1 {
    margin: 0 0 20px 0;
  }
}
