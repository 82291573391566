.products {
  width: 100%;
  height: auto;
  background: #f7f7fa;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.product-color-line {
  width: 69px;
  padding: 2px;
  background: linear-gradient(225deg, #f76680 0%, #57007b 100%);
  margin-top: 100px;
  margin-bottom: 30px;
}

.product-h1 {
  /* height: 55px; */
  font-size: 35px;
  font-weight: 400;
  /* line-height: 55px; */
  color: #1a202c;
  /* margin-top: 20px; */
  margin-bottom: 65px;
}

.product {
  width: 80%;
  height: auto;
  background: #f1f2ff;
  border: 1px solid #e7daed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 30px;
  margin-bottom: 35px;
  /* padding: 50px 0; */
}

.product-img {
  flex: 1;
  height: 350px;
  width: 100%;
  object-fit: cover;
  border-radius: 30px 0 0 30px;
}

.product-img img {
  width: 100%;
  height: 100%;
  border-radius: 30px 0 0 30px;
}

.product-text-parent {
  flex: 1;
  display: flex;
  justify-content: center;
  width: auto;
  margin: 0px 50px;
}

.product-text {
  width: auto;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
}

.product-text-h1 {
  width: auto;
  font-size: 28px;
  font-weight: 600;
  line-height: 38.09px;
  text-align: left;
  color: #2d3748;
}

.product-p1 {
  width: auto;
  font-size: 14px;
  font-weight: 400;
  line-height: 22.68px;
  text-align: left;
  color: #4a5568;
  margin: 30px 0 50px 0;
  height: 110px;
  overflow: hidden;
}

/* .product-text-h5 {
  width: 74px;
  height: 16px;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  text-align: left;
} */

.projectcase-btn-link {
  border-bottom: none !important;
}

.explore-alignment {
  width: 100%;
  height: 16px;
  font-size: 15px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 10px;
  background: linear-gradient(225deg, #f76680 0%, #57007b 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.product-btn {
  padding: 14px 25px;
  border-radius: 5px;
  border: 2px solid #d80e0e;
  font-weight: 600;
  background-color: transparent;
  cursor: pointer;
  -webkit-transition: ease-out 0.4s;
  -moz-transition: ease-out 0.4s;
  transition: ease-out 0.4s;
  color: #000000;

  margin: 35px 0;
}

.product-btn:hover {
  box-shadow: inset 400px 0 0 0 #d80e0e;
  color: white;
}

.arrow-icon {
  color: #f76680;
}

@media (max-width: 1179px) {
  .product {
    margin: auto;
    flex-direction: column;
    margin-bottom: 25px;
    padding-bottom: 30px;
  }
  .product-img {
    width: 100%;
    height: 100%;
    /* max-height: 100px; */
    object-fit: cover;
    border-radius: 30px 30px 0 0px;
  }

  .product-img img {
    width: 100%;
    height: 100%;
    max-height: 350px;
    border-radius: 30px 30px 0 0px;
  }

  .product-text-h1 {
    font-size: 22px;
    line-height: 35px;
  }

  .product-p1 {
    line-height: 1.5;
    margin: 10px 0 20px 0;
  }

  .product-text-parent {
    margin: 0px;
    padding: 0 5px 0 5px;
  }
}

@media (max-width: 768px) {
  .product {
    width: 95%;
    margin: auto;
    flex-direction: column;
    margin-bottom: 20px;
  }

  /* .product-img {
    max-width: 100%;
  } */

  /* .product-text-h1 {
    font-size: 22px;
    line-height: 35px;
  }

  .product-p1 {
    line-height: 1.5;
    margin: 10px 0 20px 0;
  } */

  .product-text-parent {
    margin: 0px;
    padding: 0 5px 0 5px;
  }
}
