@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");

* {
  margin: 0;
  padding: 0;
}

.App {
  width: 100%;
  height: auto;
}

a {
  text-decoration: none;
}
