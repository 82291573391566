.service {
  width: 100%;
  height: auto;
  /* margin-top: 60px; */
  /* margin-bottom: 10px; */
  padding-top: 100px;
  padding-bottom: 127px;
  background: radial-gradient(
    205.98% 205.98% at 50% 37.49%,
    #d80e0e 0%,
    #000000 100%
  );
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.service-h1 {
  color: #ffffff;
  margin-bottom: 40px;
}

.sub-service {
  width: 100%;

  padding-top: 52px;
  /* margin: 50px 0px 50px 0px; */
  /* padding: 40px 0; */

  /* display: flex;
  flex-direction: column; */
}

.box {
  /* display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 300px;*/
  /* width: 450px !important; */
  /* width: 70%; */
  border-radius: 35px;
  padding: 25px;

  border: 6px solid #ff002e;
  background-color: #ffffff;

  /* padding: 10px;
  border: linear-gradient(225deg, #ff002e 0%, #808080 100%);
  transition: transform 0.3s ease-in-out;
  margin: 0 20px; */
}

.box11 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;

  border-radius: 30px;
  border: 3px solid #f76680;
}

.box1 {
  width: 34px;
  height: 34px;
}

.p1 {
  font-size: 20px;
  font-weight: 600;
  color: linear-gradient(225deg, #f76680 0%, #57007b 100%) !important;
  margin-top: 10px;
  /* padding: 0 20px !important; */
}

.p2 {
  font-size: calc(0.8rem + 0.2vw);
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 400;
  color: #718096;
  margin-top: 10px;
  height: 70px;
  /* padding: 0 20px !important; */
}

.read-more-btn {
  float: right;
  color: #d80e0e;
  font-size: 15px;
  background: transparent;
  border: none;
  margin: 0;
  padding: 0;
}
.read-more-btn:hover {
  font-size: 16px;
  transition: 0.4s;
}

.slick-dots li button:before {
  color: white !important;
  opacity: 0.4 !important;
  font-size: 10px !important;
  /* margin-top: 20px; */
}

.slick-dots li.slick-active button:before {
  color: white !important;
  opacity: 1 !important;
}

.sub-service .slick-track {
  display: flex !important;
  gap: 15px;
  margin-bottom: 40px !important;
}

/* .slick-list {
  padding: 0 15px;
} */

.slick-slide {
  transition: transform 0.3s ease-in-out;
  /* height: 400px; */
}
.slick-list .slick-center {
  transform: translateY(20px);
  z-index: 1;
}

/* .slick-list {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
} */

/* .slick-slide {
  transition: transform 0.3s ease-in-out;
}

.slick-center {
  transform: translateY(20px);
  z-index: 1;
} */

@media (max-width: 1125px) {
  /* .box {
    padding: 5px;
  } */
  .slick-center {
    transform: translateY(0) !important;
  }
}

@media (max-width: 768px) {
  .slick-center {
    transform: translateY(0) !important;
  }
  .service {
    padding: 40px 0 60px 0;
  }

  .sub-service {
    padding: 0;
  }

  .slick-slide {
    margin: 0px;
  }

  .slick-slide {
    max-height: none !important;
  }

  .slick-dots {
    bottom: 0;
    margin-top: 15px;
  }

  .slick-dots li button:before {
    font-size: 13px !important;
    margin-top: 0px;
  }
}
