.contact {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 180px;
}

.sub-contact {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0 auto;
  width: auto;
  margin-top: 50px;
}

.contact-h1 {
  line-height: 20px !important;
}

.contact-heading h1 {
  width: auto;
  font-size: 35px;
  font-weight: 400;
  margin-bottom: 30px;
  text-align: center;
  line-height: 55px;
}

.form-row {
  display: flex;
  /* justify-content: space-between; */

  gap: 20px; /* Spacing between input fields */
}

.form-col {
  display: flex;
  flex-direction: column;
}

form {
  width: 800px;
}

form input {
  width: 500px;
}

form input,
form textarea {
  padding: 20px;
  border: 2px solid transparent;
  outline: none;
  background: #f7f7fa;
  color: #333;
  font-size: 1.2rem;
  margin-bottom: 1rem;
  border-radius: 10px;
  width: 100%;
  transition: border 0.4s ease-in-out;
}

/* Add border on focus */
form input:focus,
form textarea:focus {
  border: 2px solid #d80e0e;
}

textarea {
  resize: none;
  height: 250px;
  width: 100%;
}

.btnn {
  padding: 10px 25px;
  background-color: #d80e0e;
  color: white;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
  margin-top: 10px;
  width: fit-content;
}

.btnn:hover {
  background-color: white;
  color: #000;
  border: 2px solid #d80e0e;
  transition: 0.4s;
  cursor: pointer;
}

@media (max-width: 768px) {
  .contact {
    width: 95%;
    margin: auto;
  }
  .contact-heading {
    margin-top: 50px;
  }

  .contact-heading h1 {
    font-size: 25px;
    line-height: 35px;
  }

  .sub-contact {
    width: 100%;
  }

  form {
    width: 100%;
    padding: 0px 5px;
  }

  .form-row {
    flex-direction: column; /* Stack the inputs vertically on smaller screens */
    gap: 0;
  }

  form input,
  textarea {
    width: 100%; /* Ensure inputs take full width */
  }

  textarea {
    height: 150px;
  }
}

@media (max-width: 480px) {
  .contact-heading h1 {
    font-size: 20px;
    line-height: 30px;
  }

  .contact-heading {
    margin-top: 30px;
  }

  form input,
  textarea {
    font-size: 1rem;
  }
}
