.service-pages {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wraper {
  margin: 150px auto;
  width: 80%;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  overflow: auto; /* Add this to clear float */
}

.wraper img {
  border-radius: 10px;
  float: left;

  margin: 10px 25px 0 20px;
}

.pages-text-box {
  padding: 0px 20px 20px 20px;
  text-align: justify;
  text-justify: inter-word;
}

.pages-text-box h2 {
  font-size: 42px;
}

.pages-text-box p {
  font-size: 16px;
  margin-top: 30px;
}
