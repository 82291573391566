.development {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.development-heading {
  width: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  /* margin-top: 100px; */
  margin-bottom: 70px;
}

.development-color-line {
  width: 69px;
  padding: 2px;
  background: linear-gradient(225deg, #f76680 0%, #57007b 100%);
  margin-bottom: 30px;
  margin-top: 100px;
}

.development-h1 {
  font-size: 35px;
  font-weight: 400;
  /* line-height: 55px; */
  text-align: center;
}

.develop-container {
  width: 80%;
  height: 505px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  /* margin-top: 80px; */
}

.sub-container-top {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: -30px;
}

.sub-container-bottom {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: -35px;
}

.develop-step {
  padding: 35px 30px;
  margin: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e7daed;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
}

.develop-step2 {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.develop-p1 {
  width: 100%;
  overflow-wrap: break-word;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.78px;
  letter-spacing: -0.005em;
  text-align: left;
  margin-bottom: 10px;
}

.nums {
  background: linear-gradient(225deg, #f76680 0%, #57007b 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-right: 10px;
}

.develop-p2 {
  width: 100%;
  overflow-wrap: break-word;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.005em;
  text-align: left;
  color: #718096;
}

.line-cup {
  width: auto;
  display: flex;
  align-items: center;
  height: 0px;
}

.develop-color-line {
  /* width: 1150px; */

  padding: 2px 580px;
  background: linear-gradient(225deg, #f76680 0%, #57007b 100%);
}

.top-parent {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: -45px;
  margin-left: -50px;
  position: sticky;
}

.top-lines {
  margin: 0 190px;

  padding: 15px 2px;
  background: linear-gradient(225deg, #f76680 0%, #57007b 100%);
  border: none;
}

.bottom-parent {
  /* width: 1150px; */
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: -45px;
  margin-right: -45px;
  position: sticky;
}

.bottom-lines {
  margin: 0 190px;
  padding: 15px 2px;
  background: linear-gradient(225deg, #f76680 0%, #57007b 100%);
  border: none;
}

@media (max-width: 1310px) {
  .develop-container {
    height: auto;

    flex-direction: row;
  }

  .sub-container-top {
    flex-direction: column;
    margin: 0;
  }

  .develop-step2 {
    width: auto;
  }

  .bottom-parent {
    flex-direction: column;
    margin-right: 30px;
  }
  .bottom-lines {
    margin: 100px 0px;
    padding: 2px 15px;
  }
  .top-parent {
    flex-direction: column;

    margin-left: 30px;
  }

  .top-lines {
    margin: 100px 0px;
    padding: 2px 15px;
  }

  .develop-step {
    margin: 30px 0;
  }

  .develop-color-line {
    /* width: 1150px; */
    padding: 300px 1px;
    background: linear-gradient(225deg, #f76680 0%, #57007b 100%);
  }
  .cup-img {
    display: none;
  }

  .sub-container-bottom {
    flex-direction: column;
    margin: 0;
  }
}

@media (max-width: 768px) {
  .develop-container {
    width: 95%;
    margin-top: 40px;
  }

  .sub-container-top {
    margin-right: 5px;
  }
  .sub-container-bottom {
    margin-left: 5px;
  }

  .develop-step {
    padding: 0;
    margin: 15px 0;
  }
  .develop-step2 {
    padding: 7px;
  }
  .develop-p1 {
    font-size: 16px;
  }
  .develop-p2 {
    font-size: 14px;
    margin-top: 5px;
  }

  .top-parent {
    display: none;
  }

  .top-lines {
    padding: 1px 5px;
    margin: 100px 0;
  }

  .develop-color-line {
    display: none;
  }

  .bottom-lines {
    padding: 1px 5px;
    margin: 100px 0;
  }
  .bottom-parent {
    display: none;
  }
}
