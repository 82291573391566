.portfolio {
  width: 100%;
  height: auto;
}

.fortfolioimg {
  height: 800px;
  background-size: cover;
  background-position: center;
}

.portfolio-heading {
  width: 500px;
  position: relative;
  top: calc(50% - 100px);
  left: 80px;
  color: #ffffff;
  text-align: left;
}

.portfolio-h2 {
  font-size: 36px;
  font-weight: 700;
  line-height: 60px;
}

.portfolio-h1 {
  font-size: 72px;
  font-weight: 700;
  line-height: 65px;
  color: #dddddd;
}

.portfolio-p1 {
  font-size: 27px;
  font-weight: 400;
  line-height: 55px;
}

.technologies {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.tech-bar {
  width: auto;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.techs-ul {
  width: 85%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
}

.tech-li {
  list-style-type: none;
  width: auto;
  padding: 14px 25px;
  border-radius: 5px;
  font-size: 17px;
  font-weight: 600;
  cursor: pointer;
  box-shadow: 0px 6px 30px 0px #00000026;
  margin: 10px 20px;
}

.tech-li:hover {
  background-color: #d80e0e;
  color: #ffffff;
  transition: 0.3s;
}
.tech-li.active {
  background-color: #d80e0e;
  color: #ffffff;
  transition: 0.3s;
}

.tech-imgs {
  /* width: 353px;
  height: 353px; */
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  align-items: center;
  margin-top: 90px;
}

.image-container {
  position: relative;
  width: fit-content;
  overflow: hidden;
  margin: 20px;
  box-shadow: 0px 6px 15px 0px #00000040;
  border-radius: 20px;
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.image-overlay:hover {
  background-color: rgba(52, 51, 51, 0.5);
  border-radius: 18px;
}

.tech-img {
  display: block;
  height: 375px;
  width: 453px;
  border-radius: 18px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.view-img {
  padding: 14px 25px;
  border-radius: 5px;
  /* font-size: 21px; */
  border-radius: 2px solid#edeaea;
  font-weight: 600;
  background: #edeaea;
  color: #000000;
  border: none;
  display: none;
  opacity: 0.7;
}
.view-img:hover {
  background-color: white;
  opacity: 1;
  transition: all 0.2s ease-in;
}

.image-overlay:hover .view-img {
  display: block;
}

.load-btn {
  padding: 14px 25px;
  border-radius: 5px;
  border: 2px solid #d80e0e;
  /* box-shadow: 0px 4px 49px 0px #00000026; */
  font-size: 18px;
  font-weight: 600;
  color: #000000;
  background: #ffffff;
  margin-top: 70px;
  -webkit-transition: ease-out 0.4s;
  -moz-transition: ease-out 0.4s;
  transition: ease-out 0.4s;
}

.load-btn:hover {
  box-shadow: inset 400px 0 0 0 #d80e0e;
  color: white;
}
.modal-body {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  gap: 20px;
}

.modal-img-container {
  flex: 1;
  height: 100%;
}

.modal-text-container {
  flex: 1;
  color: white;
  overflow-y: auto;
  max-width: 500px;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 10px;
}

.modal-text-container h2 {
  font-size: 28px;
  margin-bottom: 25px;
}

.modal-text-container p {
  font-size: 18px;
  line-height: 1.5;
  color: white;
  text-align: justify;
  text-justify: inter-word;
}

.modal-img {
  width: 100%;
  /* max-height: 600px; */
  object-fit: cover;
  border-radius: 10px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(8px);
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  animation: fadeIn 0.3s ease-in-out;
}

.modal-content {
  position: relative;
  padding: 10px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 80vw;
  height: 85vh;
  max-width: 1300px;
  max-height: 95vh;
  background-color: #fff;
}

.close-button {
  position: absolute;
  top: -50px;
  right: 0px;
  font-size: 30px;
  cursor: pointer;
  color: #fff;
  background: none;
  border: none;
  transition: transform 0.2s ease;
  z-index: 1001;
}

.close-button:hover {
  color: #f76680;
  transform: scale(1.2);
}

@media (max-width: 768px) {
  .modal-body {
    flex-direction: column;
  }

  .modal-img {
    max-height: 40vh;
  }
}

/* @keyframes zoomIn {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}


@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
} */

@media (max-width: 1200px) {
  .portfolio-heading {
    left: 40px;
  }

  .tech-imgs {
    width: 95%;
  }

  .tech-img {
    height: 353px;
    width: 353px;
  }
}

@media (max-width: 992px) {
  .tech-bar {
    width: 95%;
  }

  .tech-li {
    padding: 10px 20px;
  }

  .tech-imgs {
    grid-template-columns: 1fr 1fr;
  }

  .tech-img {
    height: 253px;
    width: 253px;
  }

  .view-img {
    padding: 20px 40px;
  }
}

@media (max-width: 768px) {
  .fortfolioimg {
    height: 500px;
  }
  .portfolio-heading {
    width: 300px;
    top: calc(50% - 80px);
    left: 20px;
  }

  .portfolio-h1 {
    font-size: calc(1.8rem + 1vw);
    line-height: 50px;
  }

  .portfolio-h2 {
    font-size: calc(1.2rem + 0.5vw);
    line-height: 20px;
  }

  .portfolio-p1 {
    font-size: calc(0.9rem + 0.2vw);
  }

  .tech-bar {
    width: 95%;
  }

  .tech-li {
    padding: 10px 15px;
  }

  .image-container {
    padding: 10px;
  }

  .image-overlay {
    padding: 10px;
  }

  .tech-img {
    height: 100px;
    width: 100px;
  }

  .view-img {
    padding: 15px 30px;
  }

  .load-btn {
    padding: 15px 40px;
  }
}
