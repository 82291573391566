.techstack {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background: #f7f7fa;
}

.techstck-heading {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 100px;
  /* margin: 50px 0; */
}

.stack-color-line {
  width: 69px;
  padding: 2px;
  background: linear-gradient(225deg, #f76680 0%, #57007b 100%);
  margin-bottom: 30px;
}

.stack-h1 {
  font-size: 35px;
  font-weight: 400;
  /* line-height: 55px; */
  text-align: center;
}

.stack-ul {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 40px;
}
.active-tab {
  list-style-type: none;
  text-decoration: none;
  cursor: pointer;
  margin: 0 30px;
}

.active-tab.active {
  background: linear-gradient(225deg, #f76680 0%, #57007b 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.stacks {
  height: auto;
  width: 950px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

  /* justify-content: space-between;
  align-content: stretch;
  align-items: center; */

  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px; */
}

.stack-img {
  justify-content: center;
  margin-bottom: 30px;
}

/* .stack-li.a{
  border-bottom: 2px solid black;
} */

@media (max-width: 1016px) {
  .stacks {
    width: auto;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 30px;
  }

  .stack-ul {
    width: 95%;
  }

  .techstck-heading {
    width: 95%;
  }

  .active-tab {
    margin: 15px 0;
  }
}

@media (max-width: 768px) {
  .stack-ul {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .techstck-heading {
    margin-top: 30px;
  }
  .stacks {
    /* width: 95%; */
    grid-template-columns: 1fr 1fr;
    gap: 5px;
  }

  .stack-img {
    width: 150px;
    margin-bottom: 10px;
  }

  .stack-h1 {
    line-height: 45px;
  }
}
