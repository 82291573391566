.home {
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
}

.home_layout {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 80%;
  position: relative;
  height: auto;

  padding-top: 40px;
  padding-bottom: 65px;
  margin-bottom: 70px;
  margin-top: 70px;
}
/* @media (max-width: 768px) {
  .home_layout {
    width: 95%;
    margin: auto;
    height: auto;
  }
} */

.home_p {
  width: auto;
  font-size: 40px;

  font-weight: 300;
  line-height: 55px;
  text-align: left;
  margin-bottom: 25px;
}
.home_p2 {
  font-size: 15px;
  font-weight: 400;
  line-height: 30px;
  width: auto;
}

.home-img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.buttons {
  display: flex;
  gap: 23px;
  margin-top: 25px;
}

.buttons a {
  border-bottom: none;
}

.consult_but {
  padding: 14px 25px;
  border-radius: 5px;
  border: 2px solid #d80e0e;
  font-weight: 600;
  background-color: transparent;
  cursor: pointer;
  -webkit-transition: ease-out 0.4s;
  -moz-transition: ease-out 0.4s;
  transition: ease-out 0.4s;
  color: #000000;
}

.consult_but:hover {
  box-shadow: inset 400px 0 0 0 #d80e0e;
  color: white;
}

.home-img-div {
  display: flex;
  justify-content: center;
}

@media (max-width: 768px) {
  .home_layout {
    width: 95%;
    margin: auto;
    height: auto;
    flex-direction: column;
    align-items: center;
  }

  .home_text {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: auto;
    margin-top: 20px;
  }

  .home_p {
    width: auto;
    font-size: 20px;
    line-height: 28px;
  }

  .home_p2 {
    font-size: 12px;
    line-height: 20px;
  }

  .buttons {
    flex-direction: column;
    gap: 10px;
  }

  .nav_contact_button,
  .consult_but {
    width: 100%;
    max-width: 200px;
  }

  .home-img-div {
    margin-top: 30px;
  }

  .home .home-img {
    width: 100%;
    height: auto;
  }
}
