.review {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 50px 0 50px 0; */
}

.review-body {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  /* margin-top: 100px; */
}

.slider-btn {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.prev-btn {
  padding: 12px;
  border: 2px solid#57007B;
  border-radius: 40px;
  background-color: transparent;
}
.next-btn {
  padding: 12px;
  border: 2px solid#57007B;
  border-radius: 40px;
  background-color: transparent;
}

.review-text {
  width: auto;
  /* margin: 20px 0; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.review-color-line {
  width: 69px;
  padding: 2px;
  /* margin-top: -27px; */
  background: linear-gradient(225deg, #f76680 0%, #57007b 100%);
  margin-bottom: 30px;
  margin-top: 100px;
}

.review-p1 {
  width: auto;
  font-size: 35px;
  font-weight: 400;
  margin-bottom: 30px;
  text-align: center;
}

.review-p2 {
  width: 535px;
  font-size: 18px;
  font-weight: 400;
  line-height: 36px;
  text-align: center;
  color: #718096;
}

.review-show {
  width: 100%;

  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.reviews {
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.reviews-img {
  display: flex;
  justify-content: start;
  justify-content: center;
}

.stars {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 15px 0;
  /* width: 119.52px;
  height: 20px; */
}

.reviews-p1 {
  /* width: 124px;
  height: 22px; */
  font-size: 18px;
  font-weight: 700;
  letter-spacing: -0.005em;
  text-align: center;
  color: #57007b;
  line-height: 15px;
}

.reviews-p2 {
  /* width: 123px;
  height: 23px; */
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #000000;
  line-height: 15px;
}

.slider-container {
  /* width: 90%; */
  margin: auto;
}

.review .slick-slide {
  transition: transform 0.3s ease-in-out;
  padding: 40px 0;
  height: auto;
  display: flex;
  justify-content: center;
  opacity: 0.5;
}

.review .slick-center {
  opacity: 1; /* Full opacity for the center slide */
  transform: translateY(-20px) !important; /* Move the center slide up */
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out; /* Smooth transition for both transform and opacity */
}

.slick-slide .reviews {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%; /* Ensure full height for all slides */
  margin: 0;
}

.slide-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 10px;
  height: 200px;
  width: 100%;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

@media (max-width: 768px) {
  .review-body {
    width: 95%;
  }

  .review-color-line {
    margin-bottom: 30px;
  }

  .prev-btn {
    padding: 5px;
    margin-bottom: -200px;
  }
  .next-btn {
    padding: 5px;
    margin-bottom: -200px;
  }

  .review-p1 {
    width: auto;
    font-size: 35px;
    font-weight: 400;
    text-align: center;
  }

  .review-p2 {
    width: auto;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    text-align: center;
    color: #718096;
    padding: 0 5px;
  }
  /* .slick-track {
    height: 110px;
  } */

  /* 
  .reviews {
    width: 50%;
    margin-bottom: 25px;
  } */
}
